import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import ScrollReveal from 'scrollreveal';
import 'slick-carousel';

// Smooth Scroll
// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
.not('[href="#0"]')
.click(function(event) {
// On-page links
if (
  location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
  &&
  location.hostname == this.hostname
) {
  // Figure out element to scroll to
  var target = $(this.hash);
  target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
  // Does a scroll target exist?
  if (target.length) {
    // Only prevent default if animation is actually gonna happen
    event.preventDefault();
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 1000, function() {
      // Callback after animation
      // Must change focus!
      var $target = $(target);
      $target.focus();
      if ($target.is(":focus")) { // Checking if the target was focused
        return false;
      } else {
        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
        $target.focus(); // Set focus again
      };
    });
  }
}
});


// Navigation Toggle
$('#side-navigation-trigger').click(function(){
    $('.side-navigation').toggleClass('side-navigation--selected');
	$('.burger').toggleClass('burger--close');
	$('.fixed__contact').toggleClass('fixed__contact--active');
	$('body').toggleClass('stop-scroll');
});

// ----------------------------------- Start Home Slider -----------------------------------
$('.home-slider').slick({
	dots: false,
  	infinite: true,
	appendArrows: ".home-slider__arrows",
	prevArrow: "<span class='home-slider__prev btn--circle'><i class='icon-angle-left'></i></span>",
	nextArrow: "<span class='home-slider__next btn--circle'><i class='icon-angle-right'></i></span>",
});

// On before slide change
$('.home-slider').on('beforeChange', function(event, slick){
  $('.banner--triangle-left').addClass('banner--triangle-transition');
});

// On after slide change
$('.home-slider').on('afterChange', function(event, slick){
  $('.banner--triangle-left').removeClass('banner--triangle-transition');
});

// Home Slider Number of Slides
var n = $( ".home-slider .slick-slide" ).not('.home-slider .slick-cloned').length;

$( ".total-home-slide-number" ).append(n);
$( ".current-home-slide-number" ).text($( ".home-slider .slick-active" ).data( 'slick-index' ) + 1);

$('.home-slider').on('afterChange', function(){
	$( ".current-home-slide-number" ).text($( ".home-slider .slick-active" ).data( 'slick-index' ) + 1);
});
// ----------------------------------- End Home Slider -----------------------------------


// ----------------------------------- Start About Slider -----------------------------------
$('.general-slider').slick({
	dots: false,
  	infinite: true,
	draggable: false,
	fade: true,
	speed: 1600,
	appendArrows: "#general-slider-arrows",
	prevArrow: "<span class='slider__prev btn--square'><i class='icon-angle-left'></i></span>",
	nextArrow: "<span class='slider__next btn--square'><i class='icon-angle-right'></i></span>",
});
$("#general-slider-arrows").appendTo(".general-slider .slider__item .slider__content");
// ----------------------------------- End About Slider -----------------------------------


// ----------------------------------- Start Portfolio Slider -----------------------------------
$('.portfolio-slider').slick({
	dots: false,
  	infinite: true,
	draggable: false,
	fade: true,
	speed: 1200,
	appendArrows: "#portfolio-slider-arrows",
	prevArrow: "<span class='slider__prev btn--square btn--square--secondary'><i class='icon-angle-left'></i></span>",
	nextArrow: "<span class='slider__next btn--square btn--square--secondary'><i class='icon-angle-right'></i></span>",
});
$("#portfolio-slider-arrows").appendTo(".portfolio-slider .portfolio-slider__item .portfolio-slider__img");

// Portfolio Slider Number of Slides
$( ".current-portfolio-slide-number-text" ).text($( ".portfolio-slider .slick-active" ).data( 'slick-index' ) + 1);

$('.portfolio-slider').on('beforeChange', function(){
	$( ".current-portfolio-slide-number-text" ).text("");
	$( ".number-output--portfolio" ).addClass("number-output--draw");
	$( ".current-portfolio-slide-number" ).addClass("draw-active");
});
$('.portfolio-slider').on('afterChange', function(){
	$( ".current-portfolio-slide-number-text" ).text($( ".portfolio-slider .slick-active" ).data( 'slick-index' ) + 1);
	$( ".current-portfolio-slide-number" ).removeClass("draw-active");
	$( ".number-output--portfolio" ).removeClass("number-output--draw");
});
$("#portfolio-number-output").appendTo(".portfolio-slider .portfolio-slider__item .number-output");
// ----------------------------------- End Portfolio Slider -----------------------------------


// Navigation Link Hover
$(".nav-primary").hover(function() {
    $(".side-navigation").toggleClass('nav-primary--active');
	$(".fixed__item").toggleClass('nav-primary--active');
});

$(".nav-secondary").hover(function() {
    $(".side-navigation").toggleClass('nav-secondary--active');
	$(".fixed__item").toggleClass('nav-secondary--active');
});

$(".nav-grey").hover(function() {
    $(".side-navigation").toggleClass('nav-grey--active');
	$(".fixed__item").toggleClass('nav-grey--active');
});


// Scroll reveal
window.sr = ScrollReveal({ reset: false, scale: 1, distance: 0, });

sr.reveal('.fade');
sr.reveal('.fade--delay-1', { delay: 100, });
sr.reveal('.fade--delay-2', { delay: 200, });
sr.reveal('.fade--delay-3', { delay: 300, });
sr.reveal('.fade--up', { origin: 'bottom', distance: '40px', duration: 1000, });
sr.reveal('.fade--right', { origin: 'left', distance: '40px', duration: 1000, });
sr.reveal('.fade--left', { origin: 'right', distance: '40px', duration: 1000, });


// run test on initial page load
checkSize();

// run test on resize of the window
$(window).resize(checkSize);


//Function to the css rule
function checkSize() {

	// Fixed Border Colour
	if ( $( "body" ).hasClass( "fixed-colour-transition" ) && $(".fixed-colour-transition").css("clear") == "none" ) {

	    var scroll_pos = 0;
		var aboutDiv = $("#about").offset().top;
		var workshopDiv = $("#workshop").offset().top;
		var portfolioDiv = $("#portfolio").offset().top;
	    $(window).bind("scroll", function() {
	        scroll_pos = $(this).scrollTop();
			if(scroll_pos < aboutDiv) {
	            $(".fixed__item").css('background-color', '#fff');
	        }
			if(scroll_pos > aboutDiv & scroll_pos < workshopDiv) {
	            $(".fixed__item").css('background-color', '#dcdf99');
	        }
			if(scroll_pos > workshopDiv & scroll_pos < portfolioDiv) {
	            $(".fixed__item").css('background-color', '#9abbcf');
	        }
			if(scroll_pos > portfolioDiv) {
	            $(".fixed__item").css('background-color', '#c7ccc7');
	        }
	    });

	};

	if ( $(".scroll--up").css("clear") == "none" ) {
		var scroll_pos = 0;
		var footerDiv = $("#footer").offset().top;

		$(window).bind("scroll", function() {
			scroll_pos = $(this).scrollTop();
			if(scroll_pos > footerDiv) {
				$(".scroll--up").addClass('scroll--active');
			}
			if(scroll_pos < footerDiv) {
				$(".scroll--up").removeClass('scroll--active');
			}
		});

	};
}
